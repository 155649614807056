<script>
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";
import { VclCode } from 'vue-content-loading';
import { OrbitSpinner } from 'epic-spinners'
import {maxLength, minLength, required} from "vuelidate/lib/validators";

import {with_acrf_token, get_acsrf_token} from "@/methods";
import {TicketPriority, TicketStatus} from "@/enums";

import CopyableText from "@/components/CopyableText";
import {SubscriptionStatus} from "../../../enums";

export default {
  props: ['ticket_id'],
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getPersonaName'
    ])
  },
  validations: {
    request: {
      content: {
        required: required,
        maxLength: maxLength(10000)
      }
    }
  },
  components: {
    VclCode,
    simplebar,
    OrbitSpinner,
    CopyableText
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async claimTicket() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/${this.ticket_id}/claim`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$toast.success('Ticket claimed');
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }
    },
    async handOffTicket() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/${this.ticket_id}/handoff`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$toast.success('Ticket handed off');
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }
    },
    async acknowledgeTicket() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/${this.ticket_id}/acknowledge`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$toast.success('Ticket acknowledged');
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }
    },
    async resetTicket() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/${this.ticket_id}/reset`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$toast.success('Ticket status resetted');
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }
    },
    async fileDelete(fileId) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/${this.ticket_id}/gryphon/${fileId}`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$toast.success('File attachment deleted');
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }
    },
    getData() {
      let url = new URL(process.env.VUE_APP_ROOT_API + 'v1/support/ticket');
      url.searchParams.append('ticket_id', this.ticket_id);
      let that = this;
      fetch(url, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then((data) => {
          this.ticket = data.ticket;
          this.users = data.users;
          this.user = data.user;
          this.servers = data.servers;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        }).then(() => {
          if(this.$refs.chatContainer.scrollElement.offsetHeight >= 400 && this.$refs.chatContainer.scrollElement.scrollHeight > this.$refs.chatContainer.scrollElement.offsetHeight) {
            this.$refs.chatContainer.scrollElement.scrollTo(0, this.$refs.chatContainer.contentElement.offsetHeight);
          }
        });
    },
    closeRequest() {
      let ref = this;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          ticket_id: ref.ticket_id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/close', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                ref.getData();
                this.$swal({
                  icon: 'success',
                  text: this.$t('support.read.closed')
                });
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('support.read.errors.close')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('support.read.errors.close')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    },
    answerRequest() {
      if(this.submitted) return;
      this.$v.$touch();
      if(this.$v.request.$error) return;
      this.submitted = true;
      let content = this.request.content;
      let ref = this;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          ticket_id: ref.ticket_id,
          content: content
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/message', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                ref.getData();
                ref.$v.$reset();
                ref.request.content = null;
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('support.read.errors.message')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('support.read.errors.message')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }).then(() => {
        ref.submitted = false;
      });
    },
    addFile(event) {
      let file = event.target.files[0];
      let actualFileSize = file.size / 1024 / 1024; // MiB
      if (actualFileSize > 10) {
        this.$toast.warning('You are uploading a large file!');
      }
      this.uploading = true;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          ticket_id: this.ticket_id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/attachment', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              this.uploading = false;
              if (response.status == 429) {
                this.$toast.warning(this.$t('error.server.ratelimit.short'));
              } else
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            let upload_url = data.upload_url;
            let upload_token = data.upload_token;

            let resource_id = data.resource_id;

            let payload = new FormData();
            payload.append('file', file);
            payload.append('upload_token', upload_token);

            fetch(upload_url, {
              method: 'POST',
              body: payload,
              credentials: 'include'
            })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              })
              .then(data => {
                let resource_url = data.resource.url;

                with_acrf_token().then((r) => {
                  let payload = {
                    acsrf_token: r.acsrf_token,
                    resource_id: resource_id,
                    ticket_id: this.ticket_id,
                    filename: file.name
                  };
                  fetch(process.env.VUE_APP_ROOT_API + 'v1/support/register-attachment', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    credentials: 'include'
                  })
                      .then(response => {
                        if (response.ok || response.status === 400) {
                          return response.json();
                        } else {
                          if (response.status === 429) {
                            this.$toast.warning(this.$t('error.server.ratelimit.short'));

                          } else
                            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                        }
                      })
                      .then(data => {
                        if (data.status) {
                          this.uploading = false;
                          this.ticket.files.push([resource_id, file.name]);
                        } else {
                          this.uploading = false;
                          this.$toast.error(this.$t('error.server.generic.message'));
                        }
                      })
                      .catch(error => {
                        console.log(`[ERROR] ${error}`);
                        this.uploading = false;
                      });
                }).catch(error => {
                  console.log('[ERROR] Failed to request acrf token');
                  this.$toast.error(this.$t('error.server.generic.message'));
                  this.uploading = false;
                });
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.$toast.error(this.$t('error.server.generic.message'));
                this.uploading = false;
              });
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            file.error = true;
            file.processing = false;
          });
      });
    },
    filePrompt() {
      document.getElementById('file').click();
    }
  },
  created() {

  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      SubscriptionStatus: SubscriptionStatus,
      TicketPriority: TicketPriority,
      TicketStatus:TicketStatus,
      ready: false,
      error: false,
      ticket: null,
      users: {},
      user: {},
      servers: [],
      uploading: false,
      request: {
        content: null
      },
      submitted: false
    }
  }
};
</script>

<template>
  <div class="row">
    <!-- Display -->
    <template v-if="ready">
      <div class="col-lg-8 col-sm-12">
        <div class="w-100 user-chat">
          <div class="card">
            <div class="p-4 border-bottom">
              <div class="row">
                <div class="col-md-4 col-9">
                  <h5 class="font-size-15 mb-1">{{ $t('support.read.title') }} #{{ ticket.ticket_id_human }}</h5>
                  <p class="text-muted mb-0">
                    <i class="fad fa-clock align-middle mr-1"></i>
                    {{ $d(parseDate(ticket.created_at), 'datetime', 'de') }}
                  </p>
                </div>
                <div class="col-md-8 col-3">
                  <ul class="list-inline user-chat-nav text-right mb-0">
                    <li class="list-inline-item d-inline-block" v-if="ticket.status !== TicketStatus.CLOSED">
                      <button class="btn btn-rounded btn-danger" v-on:click="closeRequest()">
                        <i class="fas fa-lock-alt"></i>
                        {{ $t('support.read.actions.close') }}
                      </button>
                    </li>
                    <li class="list-inline-item d-inline-block" v-else>
                      <button class="btn btn-rounded btn-dark" disabled="disabled">
                        <i class="fas fa-lock-alt"></i>
                        {{ $t('support.read.status.closed') }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="chat-users">
              <div class="chat-conversation p-3">
                <simplebar
                    style="max-height: 400px"
                    id="chatContainer"
                    ref="chatContainer"
                >
                  <ul class="list-unstyled">
                    <li
                        v-for="message of ticket.correspondence"
                        :key="message.date+message.message"
                        :class="{ right: (message.is_bot || message.is_staff) }"
                    >
                      <div class="conversation-list">
                        <div class="ctext-wrap">
                          <template v-if="message.is_bot">
                            <div class="conversation-name">
                              Evè
                              <span class="badge badge-primary">
                                <i class="fad fa-robot"></i>
                                CFTools Bot
                              </span>
                            </div>
                          </template>
                          <template v-else-if="message.is_staff">
                            <div class="conversation-name">
                              {{ users[message.user] }}
                              <span class="badge badge-primary">
                                <i class="fas fa-user-headset"></i>
                                CFTools Staff
                              </span>
                            </div>
                          </template>
                          <template v-if="message.is_bot">
                            <p>
                              {{ $t(message.message) }}
                              <br>
                              <small class="font-size-12">
                                {{ $t('support.read.answers.footer') }}
                              </small>
                            </p>
                          </template>
                          <template v-else>
                            <p v-html="message.message" :class="{'text-left': !(message.is_bot || message.is_staff)}" />
                          </template>

                          <p class="chat-time mb-0">
                            <i class="bx bx-time-five align-middle mr-1"></i>
                            {{ $d(parseDate(message.date), 'datetime', 'de') }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </simplebar>
              </div>
              <div class="p-3 chat-input-section">
                <div class="row">
                  <div class="col" v-if="ticket.status !== TicketStatus.CLOSED">
                    <div class="position-relative">
                      <textarea
                          id="requestContent"
                          v-model="request.content"
                          rows="7"
                          class="form-control"
                          :placeholder="$t('support.request.content.placeholder')"
                          :class="{ 'is-invalid': $v.request.$error }"
                      />
                      <div v-if="$v.request.$error" class="invalid-feedback">
                        <span v-if="!$v.request.content.required">
                          {{ $t('support.request.content.errors.required') }}
                        </span>
                        <span v-if="!$v.request.content.maxLength">
                          {{ $t('support.request.content.errors.maxLength') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col" v-if="ticket.status !== TicketStatus.CLOSED">
                    <button class="btn btn-block btn-primary" v-on:click="answerRequest()" :disabled="submitted ? true : false">
                      <h4 class="m-0">
                        <template v-if="submitted">
                          <orbit-spinner v-if="submitted"
                           :animation-duration="1200"
                           :size="24"
                           class="align-middle d-inline-block"
                          />
                        </template>
                        <template v-else>
                          {{ $t('support.read.actions.send') }}
                        </template>
                      </h4>
                    </button>
                  </div>
                </div>
                <template v-if="ticket.status !== TicketStatus.CLOSED">
                  <hr>
                  <div class="row mt-2">
                    <div class="col">
                      <h6>General</h6>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting us about your problem.\n\nUnfortunately we are only able to provide support in English or German. Please rephrase your request.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Language
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting us about your problem.\n\n<msg>\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        First-Response (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nVielen Dank, dass Sie sich wegen Ihres Problems an uns wenden.\n\n<msg>\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        First-Response (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\n<msg>\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Response (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\n<msg>\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Response (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting us about your problem.\n\nTo process your request, we need additional information about your issue. Please provide us with the following:\n-\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Additional Information (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nVielen Dank, dass Sie sich wegen Ihres Problems an uns wenden.\n\nUm Ihre Anfrage zu bearbeiten, benötigen wir zusätzliche Informationen zu Ihrem Problem. Bitte geben Sie uns folgende Informationen:\n-\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Additional Information (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting us about your problem.\n\nTo process your request, we need additional information about your issue. Please provide us with the following:\n- Game server ip\n- Game port\n- RCon port\n- Steam query port\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Request Connection Details (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nVielen Dank, dass Sie sich wegen Ihres Problems an uns wenden.\n\nUm Ihre Anfrage zu bearbeiten, benötigen wir zusätzliche Informationen zu Ihrem Problem. Bitte geben Sie uns folgende Informationen:\n- Game server ip\n- Game port\n- RCon port\n- Steam query port\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Request Connection Details (DE)
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <h6>Generic</h6>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting the CFTools Cloud support team.\n\nUnfortunately, this issue does not fall into the category of issues for which we can provide assistance for.\n\nYou may reach out to the community for help via Discord (https://discord.cftools.cloud/).\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Issue No Support (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nVielen Dank, dass Sie sich an das CFTools Cloud Support-Team wenden.\n\nDas von Ihnen beschriebene Problem fällt leider nicht in die Kategorie von Problemen, für die wir unsere Hilfe anbieten.\n\nSie können optional die Community über Discord um Hilfe fragen (https://discord.cftools.cloud/).\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Issue No Support (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nThank you for contacting the CFTools Cloud support team.\n\nUnfortunately, the issue you describe does not fall into the category of issues for which we can provide assistance to our Free Plan users.\n\nYou can find some help in our knowledgebase at https://help.cftools.com/, or ask the community for help via Discord (https://discord.cftools.cloud/).\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Free No Support (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nVielen Dank, dass Sie sich an das CFTools Cloud Support-Team wenden.\n\nDas von Ihnen beschriebene Problem fällt leider nicht in die Kategorie von Problemen, für die wir unseren Free-Plan Nutzern Hilfe anbieten können.\n\nSie können einige Hilfestellungen in unserer Knowledgebase unter https://help.cftools.com/ finden, oder die Community über Discord um Hilfe fragen (https://discord.cftools.cloud/).\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Free No Support (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nwe can offer you two options:\n1) Deactivation of access. All subscriptions, servers and other user data will be deleted.\n2) Termination of the account. We will delete all your private information and activate a mechanism that will prevent our systems from collecting any data about you in the future. As a result, you will no longer be able to use CFTools services. This also includes third party services facilitated through or by CFTools. This option fulfills our deletion obligation according to GDPR.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Terminate (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nwir können Ihnen zwei Optionen anbieten:\n1) Deaktivierung des Zugangs. Alle Abonnements, Server und sonstige Nutzdaten werden gelöscht.\n2) Kündigung des Accounts. Wir werden alle Ihre privaten Informationen löschen und einen Mechanismus aktivieren, der in Zukunft keine weiteren Daten über Ihre Person speichert. Damit werden Sie auch nicht mehr in der Lage sein, Dienste von CFTools zu verwenden. Das inkludiert auch Dienste Dritter die mittels CFTools betrieben werden. Diese Option erfüllt unsere Löschpflicht nach DSGVO.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Terminate (DE)
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <h6>RCon</h6>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nYour game server does not respond to RCon connection attempts with these credentials.\n\nVerify your RCon configuration is being loaded correctly by your game server, and all ports are forwarded in your firewall. Depending on the environment, firewall or network settings may also need to be made in your server providers system. For help regarding RCon configuration, you can refer to https://docs.cftools.cloud/en/connection. If you need information or help regarding your firewall or network, please contact your server provider.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Connection-New (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nIhr Spieleserver reagiert nicht auf RCon-Verbindungsversuche mit diesen Verbindungsinformationen.\n\nSollten Sie Ihre Anmeldedaten geändert oder die Ports gewechselt haben, müssen Sie Ihre Konfiguration unter Module, Verbindungsdetails aktualisieren.\n\nSollten Sie keine Änderungen vorgenommen haben, vergewissern Sie sich, dass Ihre RCon-Konfiguration von Ihrem Spieleserver korrekt geladen wird und dass alle Ports in Ihrer Firewall weitergeleitet werden. Je nach Umgebung müssen möglicherweise auch Firewall- oder Netzwerkeinstellungen in Ihrem Server-Provider-System vorgenommen werden. Hilfe zur RCon-Konfiguration finden Sie unter https://docs.cftools.cloud/en/connection. Wenn Sie Informationen oder Hilfe zu Ihrer Firewall oder Ihrem Netzwerk benötigen, wenden Sie sich bitte an Ihren Serveranbieter.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Connection-New (DE)
                      </button>
                     
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nYour game server does not respond to RCon connection attempts with the configured credentials.\n\nShould you have changed your credentials or switched ports, you must update your configuration under Modules, Connection Details.\n\nShould you not have made any changes, verify your RCon configuration is being loaded correctly by your game server, and all ports are forwarded in your firewall. Depending on the environment, firewall or network settings may also need to be made in your server providers system. For help regarding RCon configuration, you can refer to https://docs.cftools.cloud/en/connection. If you need information or help regarding your firewall or network, please contact your server provider.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Connection-Existing (EN)
                      </button>
                       <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nIhr Spieleserver reagiert nicht auf RCon-Verbindungsversuche mit den konfigurierten Verbindungsinformationen.\n\nVergewissern Sie sich, dass Ihre RCon-Konfiguration von Ihrem Spieleserver korrekt geladen wird und dass alle Ports in Ihrer Firewall weitergeleitet werden. Je nach Umgebung müssen möglicherweise auch Firewall- oder Netzwerkeinstellungen in Ihrem Server-Provider-System vorgenommen werden. Hilfe zur RCon-Konfiguration finden Sie unter https://docs.cftools.cloud/en/connection. Wenn Sie Informationen oder Hilfe zu Ihrer Firewall oder Ihrem Netzwerk benötigen, wenden Sie sich bitte an Ihren Serveranbieter.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Connection-Existing (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nA game server cannot be added until it is set up correctly. This includes the actual game server and the RCon interface. Depending on the environment, firewall or network settings may also need to be made. For help regarding RCon configuration, you can refer to https://docs.cftools.cloud/en/connection. If you need information or help regarding your firewall or network, please contact your server provider.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Server Setup (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo ${user.username},\n\nEin Game-Server kann erst hinzugefügt werden, wenn er korrekt eingerichtet ist. Das beinhaltet den eigentlichen Game-Server sowie das RCon-Interface. Je nach Umgebung müssen auch Firewall oder Netzwerkeinstellungen vorgenommen werden. Für Hilfe bezüglich der RCon Konfiguration können Sie auf https://docs.cftools.cloud/en/connection zurückgreifen. Wenn Sie Informationen oder Hilfe zu Ihrer Firewall oder Ihrem Netzwerk benötigen, kontaktieren Sie bitte Ihren Server Anbieter.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        Server Setup (DE)
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <h6>User rejection</h6>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nCFTools does not issue ban entries. Any ban entries come from community administrators and are related to their game servers.\n\nWe do not remove ban entries or process any ban appeals.\nPlease contact the issuing community for more information.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Ban Appeals (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click='request.content = `Hallo ${user.username},\n\nCFTools stellt keine Ban-Einträge aus. Jegliche Ban-Einträge stammen von Community Administratoren und sind bezogen auf deren Game-Server.\n\nWir entfernen keine Ban-Einträge und bearbeiten keine Entbannungsanträge.\nKontaktieren Sie die Community für mehr Informationen.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`'>
                        Ban Appeals (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nCFTools provides it's users with information about players in order to help them administrate their servers and process inquiries.\n\nAlternate account detection is one of the tools we provide to our users and it works based on various different pieces of data in order to establish a link between player profiles.\n\nThis process is automated and does not accept any manual interference.\nShould our system no longer deem a connection relevant, it will be removed.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Alternate Accounts (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click='request.content = `Hallo ${user.username},\n\nCFTools bietet ihren Nutzern informationen über Spieler, damit diese ihre Game Server besser administrieren und Anfragen beantworten können.\n\nDie verknüpfung zwischen Spielerkonten ist eines dieser Tools, welches wir unseren Nutzern zur Verfügung stellen. Dieses arbeitet dabei mit diversen Informationspunkten um Verknüpfungen zwischen Spielerprofilen herzustellen.\n\nDieser Prozess ist automatisiert und erlaubt keine manuelle Überschreibung.\nSobald das System eine Verknüpfung für nicht mehr relevant hällt, wird diese automatisch gelöscht.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`'>
                        Alternate Accounts (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello ${user.username},\n\nban list public listing, is a powerful tool, we offer to eligible ban lists.\n\nWhether or not any ban list is eligible for public listing, depends on its rating within the set category. The rating is based on various factors and not solely dependent on individual ones. To prevent abuse, we can not disclose what factors are used, or why any specific ban list is listed or not listed.\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Ban List Public Listing (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click='request.content = `Hallo ${user.username},\n\nDie öffentliche Auflistung von Verbotslisten ist ein leistungsfähiges Instrument, das wir für geeignete Verbotslisten anbieten.\n\nOb eine Verbotsliste für die öffentliche Auflistung geeignet ist oder nicht, hängt von ihrer Bewertung innerhalb der festgelegten Kategorie ab. Die Bewertung basiert auf verschiedenen Faktoren und ist nicht nur von einzelnen Faktoren abhängig. Um Missbrauch vorzubeugen, können wir nicht offenlegen, welche Faktoren verwendet werden oder warum eine bestimmte Verbotsliste gelistet oder nicht gelistet wird.\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`'>
                        Ban List Public Listing (DE)
                      </button>

                      <!--
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello,\n\nCFTools does not issue ban entries. Any ban entries come from community administrators and are related to their game servers.\n\nWe do not remove ban entries as a matter of principle. If your profile has a public ban entry from a verified ban list (Verified Badge), then it must adhere to our Verified Ban List Policy.\n\nWe would be happy to verify your profile in this regard. To do so, send an email from your verified email address to info@cftools.cloud with the subject Ban Review. We do not accept such cases through our ticket system.\nYour profile does not have any public ban entries of verified ban lists, accordingly no review will be performed.\n\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        Ban-Review (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click='request.content = `Hallo,\n\nCFTools stellt keine Ban-Einträge aus. Jegliche Ban-Einträge stammen von Community Administratoren und sind bezogen auf deren Game-Server.\n\nWir entfernen grundsätzlich keine Ban-Einträge. Sollte Ihr Profil einen öffentlichen Ban-Eintrag einer verifizierten Ban-Liste besitzen (Verifiziert-Badge), dann muss sich dieser an unsere Richtlinien für verifizierte Ban-Listen halten.\n\nGerne können wir Ihr Profil diesbezüglich überprüfen. Senden Sie hierzu von Ihrer verifizierten E-Mail Adresse eine E-Mail an info@cftools.cloud mit dem Betreff Ban-Review. Über unser Ticket-System nehmen wir keine solcher Fälle an.\nIhr Profil besitzt keine öffentliche Ban-Einträge verifizierter Ban-Listen, entsprechend wird keine Überprüfung durchgeführt.\n\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`'>
                        Ban-Review (DE)
                      </button>

                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hello,\n\nAs announced and communicated via e-mail, DayZ 1.13 introduced changes to the way RCon works.\nRead more at: https://wiki.cftools.de/pages/viewpage.action?pageId=11796696\n\nSincerely,\n${getPersonaName()} - CFTools Cloud`">
                        DayZ 1.13 (EN)
                      </button>
                      <button class="ml-1 mr-1 btn btn-info text-black btn-rounded" v-on:click="request.content = `Hallo,\n\nwie bereits per E-Mail mitgeteilt, wurde mit DayZ 1.13 eine Änderung an der RCon Funktionalität eingespielt.\nMehr unter: https://wiki.cftools.de/pages/viewpage.action?pageId=11796696\n\nMit freundlichen Grüßen\n${getPersonaName()} - CFTools Cloud`">
                        DayZ 1.13 (DE)
                      </button>
                      -->
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card">
          <div class="card-body">
            <template v-if="user.subscription">
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    Subscription
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ user.subscription.product }}
                  <template v-if="user.subscription.status === SubscriptionStatus.ACTIVE">
                    <span class="badge badge-success text-black">
                      ACTIVE
                    </span>
                  </template>
                  <template v-else-if="user.subscription.status === SubscriptionStatus.CANCELLED">
                    <span class="badge badge-danger text-black">
                      CANCELLED
                    </span>
                  </template>
                  <template v-else-if="user.subscription.status === SubscriptionStatus.EXPIRED">
                    <span class="badge badge-warning text-black">
                      EXPIRED
                    </span>
                  </template>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    Created at
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ $d(parseDate(user.subscription.created_at), 'datetime', 'de') }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    Expires at
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ $d(parseDate(user.subscription.expires_at), 'datetime', 'de') }}
                </div>
              </div>
            </template>
            <template v-else>
              <h3 class="text-center text-uppercase">no subscription</h3>
            </template>
          </div>
        </div>
        <div class="mt-4 mb-4" />
        <div class="card">
          <div class="card-body">
            <template v-if="servers.length">
              <div class="row" v-for="server in servers" :key="server.id">
                <div class="col-lg-2 col-sm-12 text-white text-center justify-content-center">
                  <img :src="'/' +gameIcon(server.game)" height="16px">
                </div>
                <div class="col-lg-10 col-sm-12 text-center">
                  <a :href="'https://data.cftools.cloud/v1/gameserver/' + server.gameserver_id" target="_blank">{{ server.identifier }}</a>

                  <CopyableText class="ml-1 text-code" :text="server.id"/>
                </div>
                <template v-if="server.privileged">
                  <span class="badge badge-info text-black">
                    ORG AUTH
                  </span>
                </template>
              </div>
            </template>
            <template v-else>
              <h3 class="text-center text-uppercase">no servers</h3>
            </template>
          </div>
        </div>
        <div class="mt-4 mb-4" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.created')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ $d(parseDate(ticket.created_at), 'datetime', 'de') }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.updated')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ $d(parseDate(ticket.updated_at), 'datetime', 'de') }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  Account Id
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <CopyableText class="text-code" :text="user.cftools_id"/>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.reference')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ ticket.reference }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.status.title')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <template v-if="ticket.status === TicketStatus.CLOSED">
                  <span class="badge badge-light text-dark">
                    <b>CLOSED</b>
                  </span>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_USER">
                  <span class="badge badge-success text-light">
                    <b>WAITING_FOR_USER</b>
                  </span>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_SUPPORT">
                  <span class="badge badge-info">
                    <b>WAITING_FOR_SUPPORT</b>
                  </span>
                  -
                  <a href="javascript:void(0);" v-on:click="resetTicket()">
                    Reset
                  </a>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_TRIAGE">
                  <span class="badge badge-warning text-light">
                    <b>WAITING_FOR_TRIAGE</b>
                  </span>
                  -
                  <a href="javascript:void(0);" v-on:click="acknowledgeTicket()">
                    Acknowledge
                  </a>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.assignee.title')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <template v-if="ticket.assignee">
                  {{ users[ticket.assignee] }}
                  -
                  <a href="javascript:void(0);" v-on:click="handOffTicket()">
                    Hand off
                  </a>
                </template>
                <template v-else>
                  <i>{{$t('support.read.status.assignee.not_assigned')}}</i>
                  -
                  <a href="javascript:void(0);" v-on:click="claimTicket()">
                    Claim
                  </a>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.priority.title')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <template v-if="ticket.priority === TicketPriority.NONE">
                  <i>{{$t('support.read.status.priority.none')}}</i>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.LOW">
                  <b>{{$t('support.read.status.priority.low')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.MEDIUM">
                  <b>{{$t('support.read.status.priority.medium')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.HIGH">
                  <b>{{$t('support.read.status.priority.high')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.CRITICAL">
                  <b>{{$t('support.read.status.priority.critical')}}</b>
                </template>
              </div>
            </div>
            <hr>
            <h6 class="m-0 text-uppercase">
              {{$t('support.read.status.files')}}
              <template v-if="ticket.status !== TicketStatus.CLOSED">
                <button class="ml-2 btn btn-rounded btn-primary btn-sm" :disabled="uploading ? true : false" v-on:click="filePrompt()">
                  <i class="fad fa-cloud-upload"></i>
                  {{$t('support.read.actions.upload')}}
                </button>
                <span class="ml-2 small text-muted" v-if="uploading">
                  <i class="fas fa-spinner fa-spin"></i>
                  Uploading...
                </span>
                <input type="file" v-on:change="addFile($event)" id="file" style="display: none;" ref="file">
              </template>
            </h6>
            <ul class="ml-0 pl-0 mt-2" style="list-style: none;">
              <li v-for="file in ticket.files" :key="file[0]+Math.random()">
                <i class="fad fa-file-alt align-middle"></i>
                <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                  {{file[1]}}
                </a>
                <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                  <span class="ml-1 small text-muted">
                    {{$t('support.read.actions.download')}}
                  </span>
                </a>
                <a href="#" v-on:click="fileDelete(file[0])">
                  <span class="ml-1 small text-danger">
                    Delete
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <!-- Error -->
    <template v-else-if="error">
      <div class="col-lg-12 col-sm-12 row justify-content-center">
      <div class="col-lg-6 col-sm-12">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i>
              {{$t('error.server.generic.title')}}
            </h5>
          </div>
          <div class="card-body pt-0">
            <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
          </div>
        </div>
      </div>
      </div>
    </template>
    <!-- Loading -->
    <template v-else>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <h5 class="text-uppercase">{{$t('support.read.title')}} #{{ticket_id}}</h5>
            <vcl-code :height="90"></vcl-code>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <vcl-code :height="90"></vcl-code>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
